/* You can add global styles to this file, and also import other style files */
@use "sass:math";
@use '@angular/material' as mat;
@import 'vars';
@import 'media';
// @import 'styles-katex';

html {
  &.cdk-global-scrollblock {
    overflow-y: auto;
  }
}

body {
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: $almostblack;
  width: 100%;
  height: 100%;

  @media #{$gt-xs} {
    /* Disables rubber-banding "pull to refresh/overflow" */
    position: fixed;
    overflow: hidden;
  }
}


a:not(.mat-mdc-tab-link) {
  text-decoration: none;
}

[ng-reflect-router-link] {
  cursor: pointer;
}

.mat-icon {
  opacity: 0.54;

  &.opaque {
    opacity: 1;
  }
}

.mat-raised-button {
  .mat-icon {
    opacity: inherit;
  }
}

.fab-extended {
  background-color: #f5f5f5;
  border-radius: 18px !important;
  padding: 0 6px !important;
  font-size: 12px;

  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;

    mat-icon {
      margin-right: 2px;
      opacity: 0.7;
    }
  }
}

.mat-bottom-sheet-container {
  &.no-padding {
    padding: 0;
  }

  &.no-overflow {
    overflow: hidden;
  }
}

button, .mat-button, .mat-raised-button {
  text-transform: uppercase;

  .muted {
    opacity: 0.54;
  }

  .mat-icon + span {
    margin-left: 4px;
  }
}

.mat-option {
  line-height: 36px;
  height: 36px;
  font-size: 14px;
}

html {
  // Autocomplete
  // These autocompletes are the entity-context components and have more info in them
  .mat-autocomplete-panel.entities {
    min-width: 280px;
  }

  // Dialogs
  .mat-mdc-dialog-container {
    overflow: initial;
    height: initial;

    @media #{$lt-md} {
      padding: 16px;
    }

    .mat-mdc-dialog-title {
      display: flex;

      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      h2 {
        font-size: 22px;
        margin: 0;
      }
    }
    h2.mat-mdc-dialog-title {
      font-size: 22px;
    }
    .mat-mdc-dialog-title, .mdc-dialog__content {
      font-family: unset;
      line-height: unset;
      letter-spacing: unset;
      font-weight: unset;
    }

    .mdc-dialog__surface {
      overflow: initial;
    }

    .mat-mdc-dialog-actions {
      background: white;
      z-index: 2;
    }
  }

  // Allows hiding of MatDialogs without explicitly closing them (via MatDialogRef.addPanelClass('hidden'))
  .bild-hidden-dialog .mat-mdc-dialog-container {
    display: none;
  }

  // Cards
  .mat-card {
    padding: 0;
    margin: 0 0 16px 0;
    @media #{$lt-md} {
      margin-bottom: 8px;
    }
    border: 1px solid #dadce0;
    border-radius: 8px;
    overflow: hidden;

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    .mat-card-header {
      .mat-card-avatar {
        padding: 16px 8px 8px 16px;

        .mat-icon {
          font-size: 40px;
          height: 40px;
          width: 40px;
          color: #757575;
        }

        & ~ .mat-card-header-text {
          margin-left: 0;
        }
      }
    }

    .mat-card-title {
      font-size: 18px;
      padding: 16px 0 0;
    }

    .mat-card-subtitle {
      padding: 0;
    }

    @media #{$lt-md} {
      .mat-card-header-text {
        margin: 0 8px;
      }
      .mat-card-title {
        padding-top: 8px;
      }
    }

    &.empty-card {
      font-size: 14px;
      font-weight: 400;
      padding: 16px;
    }

    &.header-card {
      background-color: transparent;
      box-shadow: none;
      overflow-y: hidden;
      border: none;

      .mat-card-title {
        padding: 0;
        margin: 0 0 8px 0;
      }

      .mat-card-subtitle {
        margin-top: 8px;
        padding: 0;
        margin: 0;
      }
    }

    > .mat-table {
      > .mat-row:last-child {
        border-bottom: none;
      }
    }

    > .mat-card-content {
      padding: $base-spacing * 0.5 $base-spacing;

      > .mat-table {
        > .mat-row:last-child {
          border-bottom: none;
        }
      }
    }

    .mat-card-actions {
      padding: 8px;
      display: flex;
      justify-content: flex-end;
    }

    > view-more:last-child:not(.mat-card-footer) {
      margin: 8px 16px 8px;
    }
  }

  // Chips
  .mat-chip-list-wrapper {
    align-items: center;
    margin-top: 4px;
  }
  input.mat-chip-input {
    margin: 2px;
    padding-bottom: 2px;
  }
  .mat-chip:not(.mat-basic-chip) {
    margin: 0 4px 4px 0;
    &+.mat-chip:not(.mat-basic-chip) {
      margin: 0 4px 4px 0;
    }
  }

  .mat-expansion-panel-content.mat-expanded {
    overflow: hidden;
  }

  .mat-menu-panel {
    max-width: 325px;

    .mat-menu-content {
      .mat-menu-item {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
      }
    }
  }

  @media #{$lt-md} {
    .mat-table {
      .mat-cell {
        font-size: 10px;
      }
      .mat-header-cell {
        font-size: 10px;
      }
    }
    .mat-header-row, .mat-row {
      padding: 0 8px;

      .mat-column-actions {
        visibility: hidden;
      }
    }
    .mat-cell.mat-column-actions {
      visibility: visible;

      .mat-icon-button {
        width: 24px;
        height: 24px;
        line-height: 24px;

        .mat-icon {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
    .mat-cell, .mat-header-cell {
      .mat-sort-header-container {
        .mat-sort-header-arrow {
          margin: 0;
        }
      }
      &:not(:first-child) {
        margin-left: 4px;
      }
      &:last-child {
        margin-left: 0px;
        margin-right: 4px;
      }
    }
    .mat-sort-header-arrow {
      margin: 0;
    }
  }

  .lt-lg {
    .mat-table {
      .mat-cell {
        font-size: 12px;
        &.percentile {
          font-size: 9px;
          color: lightgray;
        }
      }
      .mat-header-cell {
        font-size: 13px;
      }
    }
  }
}

.mat-card, .mat-drawer-container {
  color: $almostblack;
}

table:not(.mat-calendar-table) tr {
  height: 32px;
  min-height: 32px;
}

.mat-calendar-previous-button, .mat-calendar-next-button {
  height: 40px !important;
  width: 40px !important;
  font-size: 40px !important;
}

// Material table overrides
.mat-cell {
  color: $black;
  font-size: 13px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;

  > a {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }

  .ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.mat-header-cell {
  color: $black-muted;
  display: flex;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.mat-cell, .mat-header-cell {

  &.numeric {
    text-align: right;
    justify-content: flex-end;

    .mat-sort-header-container {
      .mat-sort-header-arrow {
        margin: 0 0 0 2px;
      }

      .mat-sort-header-position-before {
        .mat-sort-header-arrow {
          margin: 0 2px 0 0;
        }
      }

      .mat-sort-header-content {
        text-align: right;
      }
    }
  }
  &.percentile {
    font-size: 9px !important;
    color: lightgray;

    .mat-sort-header-container {
      .mat-sort-header-content {
        text-align: left;
      }
    }
  }
  &:not(:first-child) {
    margin-left: 16px;
  }

  .mat-sort-header-container {
    align-items: center;
  }
}

.scrollable-table-set {
  display: flex;
  flex-direction: column;

  .scrollable-table-set-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;

    .mat-table {
      grid-column-end: span 1;
    }
    .mat-table.fixed-table, .mat-table.scrollable-table {
      .mat-header-row, .mat-row {
        padding: 0 16px;

        @media #{$lt-md} {
          padding: 0 8px;
        }
      }
    }
    .mat-table.fixed-table {
      grid-column-start: 1;

      .mat-header-row, .mat-row {
        padding: 0 0 0 16px;

        @media #{$lt-md} {
          padding: 0 0 0 8px;
        }
      }
    }
    .mat-table.scrollable-table {
      grid-column-start: 2;
      .mat-header-row, .mat-row {
        padding-left: 0;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 14px;
        height: 14px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 3px solid #fff;
        background-color: rgba(0, 0, 0, .3);
      }
    }
  }
  .mat-header-cell {
    font-size: 11px !important;
  }
  .mat-cell {
    font-size: 12px;
  }
  .mat-header-cell, .mat-cell {
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
  .extended {
    .scrollable-table {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}

@media #{$lt-md} {
  .scrollable-table-set {
    .mat-table {
      .mat-header-cell {
        font-size: 11px;
      }
      .mat-cell {
        font-size: 12px;
        &.percentile {
          font-size: 9px;
          color: lightgray;
        }
      }
    }
  }
}

// Material paginator overrides
.mat-paginator {

  // Hide the paginator page size control globally,
  // Material is working on a way to do this through config, not available yet
  .mat-paginator-page-size {
    display: none;
  }
}

bild-logo {
  display: block;
}

a {
  &:not(.mdc-tab) {
    color: $link-color;

    &.mat-menu-item, &.mat-button-base {
      color: inherit;
    }
  }
  &.mdc-tab {
    opacity: 0.54;
    font-weight: 500;
  }
}
.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid rgba(0,0,0,.12);
}
.mat-mdc-tab-link {
  text-decoration: none;
}
.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link, .mat-mdc-tab.mdc-tab {
  flex-grow: 0;
  @media #{$lt-md} {
    font-size: 14px;
  }
}
.mdc-tab__content {
  &:not(.mdc-tab--active) {
    color: black;
  }
}
.mdc-tab--active .mdc-tab__content {
  color: $link-color;
}
.mat-mdc-tab .mdc-tab__text-label {
 font-weight: 500;
}
.mdc-tab__content {
  .mat-checkbox-ripple {
    display: none;
  }

  .mat-checkbox {
    margin: 0 12px;
  }
}
.mat-mdc-tab-header {
  border-bottom: 1px solid #dadce0;

  .mat-mdc-tab {
    &:not(.mdc-tab--active) {
      .mdc-tab__content {
        color: grey;
      }
    }
  }
}
.mat-mdc-tab-group-stretch-tabs {
  .mat-mdc-tab {
    flex-grow: 1;
  }
}

.mat-mdc-tab-header-pagination {
  color: grey;
}

.mat-mini-fab:not([class*=mat-elevation-z]).flat {
  box-shadow: none;

  &:hover {
    @include mat.elevation(2);
  }
}

textarea, input, button { outline: none; }

[hidden] {
  display: none !important;
}

.ngx-loading-bar {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

.ngx-loading-bar-progress {
  margin: 0;
  padding: 0;
  z-index: 99998;
  background-color: green;
  color: green;
  box-shadow: 0 0 10px 0;
  height: 4px;
  opacity: 0;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.profile.mat-menu-panel, .notifications-menu.mat-menu-panel {
  min-width: 350px;
  border-radius: 10px;
  &> .mat-menu-content {
    padding: 0px;

    .header {
      align-items: center;

      .avatar {
        margin-right: 16px;
      }
    }
  }
}

.profile.mat-menu-panel {
  &> .mat-menu-content {
    padding: 16px;

    .header {
      margin-bottom: 8px;
    }
  }
}

.small.mat-menu-panel {
  min-height: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}


.mat-toolbar {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }
}

mat-toolbar.search {
}

.mat-toolbar.header {
  background-color: transparent;
  min-height: inherit;
  margin: 12px 0 16px;

  .mat-toolbar-row {
    display: flex;
    justify-content: space-between;
    padding: 0;
    height: inherit;

    .entity {
      display: flex;
      align-items: center;

      .mat-icon {
        font-size: 56px;
        margin-right: 16px;
      }

      entity-avatar {
        margin-right: 16px;
      }

      .names {
        display: flex;
        flex-direction: column;

        a {
          color: inherit;
        }

        .primary {
          line-height: 20px;
          height: initial;
          min-height: initial;
          white-space: normal;
        }
        .secondary {
          font-size: 12px;
          line-height: 12px;
          height: initial;
          min-height: initial;
          font-weight: 400;
          color: #757575;
          margin-top: 2px;
          white-space: normal;
        }
      }
    }

    .name-description {
      display: flex;
      flex-direction: column;

      .name {
        line-height: 20px;
        height: initial;
        min-height: initial;
        white-space: normal;
      }
      .description {
        font-size: 12px;
        line-height: 12px;
        height: initial;
        min-height: initial;
        font-weight: 400;
        color: #757575;
        margin-top: 2px;
        white-space: normal;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      margin-left: 16px;

      .mat-slide-toggle {
        font-size: initial;
        font-weight: initial;
        margin-right: 8px;
      }

      button:not(:last-child) {
        margin-right: 8px;
      }
      button.print {
        padding: 0;
        min-width: 42px;
      }

      button.primary-action {
        border-radius: 18px;
        padding: 0 6px 0 6px;
        min-width: 0;
      }
    }
  }

  [mat-button]:last-of-type:not(:first-child) {
    margin-right: 0;
  }
}

// Froala
@mixin fr-background {
  background: none;
  box-shadow: none;
}

.fr-toolbar, .fr-toolbar.fr-bottom {
  @include fr-background;
  border: none;
  padding: 0;
  .fr-newline {
    display: none;
  }
  .fr-svg {
    width: 22px !important;
    height: 22px !important;
  }
}

.fr-box {
  &.fr-basic {
    .fr-wrapper {
      border: none !important;
      @include fr-background;
    }
    .fr-element {
      font-family: Roboto, "Helvetica Neue", sans-serif;
      p:first-child {
        margin-top: 0;
      }
      p:last-child {
        margin-bottom: 0;
      }
      p {
        min-height: 22.4px;
      }
      padding: 0;
    }
  }
}

.fr-popup .fr-color-set > span:not(:last-child) {
  border-radius: 25px;
  margin-right: 8px;
}

.fr-toolbar .fr-btn-grp {
  margin: 0 12px 0 12px;
}

// Hide paperclip after attachments
.fr-file::after {
  display: none;
}

.fr-toolbar {
  &.fr-top {
    @include fr-background;
  }
  &.fr-inline {
    background: white;
  }
}

.fr-popup {
  .fr-buttons, .fr-layer.fr-color-hex-layer {
    display: none;
  }
  .fr-color-set.fr-selected-set {
    padding: 20px;
  }
}

// TODO: Figure out what this was for and make sure it doesn't get rid of the link address when we add it back
//.fr-input-line {
//  display: none;
//}

// InnerHTML comment styles
.comment {
  .container {
    .atwho-inserted a {
      font-weight: 400;
      text-decoration: underline;
      cursor: pointer;
    }

    > p {
      margin-top: 0;
    }
  }
}

// At mention drop-down
.tribute-container {
  width: 220px;
  border: solid 1px #CCCCCC;
  ul {
    background-color: white;
    li {
      padding: 8px;
      display: flex;

      .name, .extra {
        display: flex;
        line-height: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        > * {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .name {
        flex-direction: column;
        margin-left: 4px;
        small {
          color: #757575;
        }
      }

      .extra {
        margin-left: auto;
      }

      &.team {
        .extra {
          justify-content: flex-start;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &.highlight {
        color: white;
        small {
          color: white !important;
        }
        background-color: $color-thunder-navy;
      }
    }
    li:not(:last-child) {
      border-bottom: solid 1px #CCCCCC;
    }
  }
}

a.mat-mini-fab {
  box-shadow: none;
}

comment .container {
  .content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .table-wrapper {
    overflow-x: auto;
    overflow-wrap: normal;
    word-wrap: normal;
    word-break: normal;

    hyphens: none;
  }

  .table-wrapper::-webkit-scrollbar
  {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
  }

  .table-wrapper::-webkit-scrollbar-thumb
  {
    border-radius: 8px;
    border: 3px solid #fff;
    background-color: rgba(0, 0, 0, .3);
  }
}

comment .container {
  .content {
    p:first-child {
      margin-top: 4px;
    }
  }
}

// Material seems to have changed some styles on form fields
.mat-toolbar-row .mat-form-field-infix {
  border-top: 0;
}

.mat-form-field {
  input {
    line-height: 1.3em;
  }
}

.mat-drawer-content {
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column;
  height: 100%;
}

mat-toolbar.mobile {
  padding: 0;

  mat-icon-button mat-icon {
    margin: 8px;
  }
}

// Provide enough padding in expansion panels for inking UI to look correct
.mat-expansion-panel {
  .mat-expansion-panel-body {
    margin: 0;
    padding: 15px 15px 5px;
  }
  .mat-expansion-panel-content {
    margin: 0;
  }
}

.columns.three, .columns.two {
  .left-shoulder {
    .mat-list {
      margin-top: 8px;
    }
  }
  .middle {
    > h1 {
      font-size: 20px;
      font-weight: 500;
    }
    > h2 {
      font-size: 16px;
      font-weight: 400;
    }
    // Extra bottom padding on mobile and iPad to account for scrolling behavior
    @media #{$lt-lg} {
      padding-bottom: 64px !important;
    }
  }
}

.sortable-table-header {
  cursor: pointer;

  &.asc {
    text-decoration: underline;
  }
  &.desc {
    text-decoration: overline;
  }
}

.no-padding-dialog {
  .mat-mdc-dialog-surface, .mat-mdc-dialog-content {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.mat-mdc-dialog-container {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;

  .mdc-dialog__surface {
    padding: 24px 24px 12px 24px;
    border-radius: 8px !important;
  }

  .mdc-dialog__content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 8px !important;
    overflow-x: hidden;
  }

  .mdc-dialog__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;

    &::before {
      display: none !important;
    }
  }
}


// For use when you need to style the text color based on percentile value
// TODO Add additional style rules if needing to set the bg instead
%percentile-text-bin-1 { color: $percentile-min !important; }
%percentile-text-bin-1a { color: mix($percentile-min, $almostblack, 66%) !important; }
%percentile-text-bin-2 { color: mix($percentile-min, $almostblack, 66%) !important; opacity: 0.8; }
%percentile-text-bin-3 { color: inherit !important; opacity: 0.6; }
%percentile-text-bin-4 { color: mix($percentile-max, $almostblack, 66%) !important; opacity: 0.8; }
%percentile-text-bin-4a { color: mix($percentile-max, $almostblack, 66%) !important; }
%percentile-text-bin-5 { color: $percentile-max !important; }
%background-percentile-text-bin-1 { fill: mix($percentile-min, $almostwhite, 66%);  background-color: mix($percentile-min, $almostwhite, 66%); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-1a { fill: mix($percentile-min, $almostwhite, 66%);  background-color: mix($percentile-min, $almostwhite, 66%); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-2 { fill: mix($percentile-min, $almostwhite, 52.8%);  background-color: rgba(mix($percentile-min, $almostwhite, 66%), 0.8); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-2a { fill: mix($percentile-min, $almostwhite, 62.7%); background-color: rgba(mix($percentile-min, $almostwhite, 66%), 0.95); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-2b { fill: mix($percentile-min, $almostwhite, 49.5%); background-color: rgba(mix($percentile-min, $almostwhite, 66%), 0.75); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-2c { fill: mix($percentile-min, $almostwhite, 49.5%); background-color: rgba(mix($percentile-min, $almostwhite, 66%), 0.75); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-2d { fill: mix($percentile-min, $almostwhite, 42.9%); background-color: rgba(mix($percentile-min, $almostwhite, 66%), 0.65); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-2e { fill: mix($percentile-min, $almostwhite, 36.3%); background-color: rgba(mix($percentile-min, $almostwhite, 66%), 0.55); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-2f { fill: mix($percentile-min, $almostwhite, 29.7%); background-color: rgba(mix($percentile-min, $almostwhite, 66%), 0.45); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-2g { fill: mix($percentile-min, $almostwhite, 23.1%); background-color: rgba(mix($percentile-min, $almostwhite, 66%), 0.35); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-3 { fill: #dadada;  background-color: #b3b3b31e; color: rgba(0, 0, 0, 0.4); text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-4 { fill: mix($percentile-max, $almostwhite, 23.1%); background-color: rgba(mix($percentile-max, $almostwhite, 66%), 0.35); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-4a { fill: mix($percentile-max, $almostwhite, 29.7%); background-color: rgba(mix($percentile-max, $almostwhite, 66%), 0.45); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-4b { fill: mix($percentile-max, $almostwhite, 36.3%); background-color: rgba(mix($percentile-max, $almostwhite, 66%), 0.55); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-4c { fill: mix($percentile-max, $almostwhite, 42.9%); background-color: rgba(mix($percentile-max, $almostwhite, 66%), 0.65); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-4d { fill: mix($percentile-max, $almostwhite, 49.5%); background-color: rgba(mix($percentile-max, $almostwhite, 66%), 0.75); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-4e { fill: mix($percentile-max, $almostwhite, 56.1%); background-color: rgba(mix($percentile-max, $almostwhite, 66%), 0.85); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}
%background-percentile-text-bin-5 { fill: mix($percentile-max, $almostwhite, 62.7%); background-color: rgba(mix($percentile-max, $almostwhite, 66%), 0.95); color: $lightgreysmoke; text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center}

@for $i from 0 through 100 {
  .percentile-text-#{$i} {
    @if $i <= 10 { @extend %percentile-text-bin-1; }
    @else if $i < 25 { @extend %percentile-text-bin-1a; }
    @else if $i < 40 { @extend %percentile-text-bin-2; }
    @else if $i < 60 { @extend %percentile-text-bin-3; }
    @else if $i < 75 { @extend %percentile-text-bin-4; }
    @else if $i < 90 { @extend %percentile-text-bin-4a; }
    @else { @extend %percentile-text-bin-5; }
  }
  .background-percentile-text-#{$i} {
    @if $i < 3 { @extend %background-percentile-text-bin-1; }
    @else if $i < 5 { @extend %background-percentile-text-bin-2b; }
    @else if $i < 10 { @extend %background-percentile-text-bin-2c; }
    @else if $i < 15 { @extend %background-percentile-text-bin-2d; }
    @else if $i < 20 { @extend %background-percentile-text-bin-2e; }
    @else if $i < 25 { @extend %background-percentile-text-bin-2f; }
    @else if $i < 40 { @extend %background-percentile-text-bin-2g; }
    @else if $i < 60 { @extend %background-percentile-text-bin-3; }
    @else if $i < 75 { @extend %background-percentile-text-bin-4; }
    @else if $i < 80 { @extend %background-percentile-text-bin-4a; }
    @else if $i < 85 { @extend %background-percentile-text-bin-4b; }
    @else if $i < 90 { @extend %background-percentile-text-bin-4c; }
    @else if $i < 95 { @extend %background-percentile-text-bin-4d; }
    @else if $i < 97 { @extend %background-percentile-text-bin-4e; }
    @else { @extend %background-percentile-text-bin-5; }
  }
}

@for $i from 1 through 31 {
  .rank-text-#{$i} {
    @if $i <= 5 { @extend %percentile-text-bin-5; }
    @else if $i < 10 { @extend %percentile-text-bin-4a; }
    @else if $i < 15 { @extend %percentile-text-bin-4; }
    @else if $i < 20 { @extend %percentile-text-bin-3; }
    @else if $i < 25 { @extend %percentile-text-bin-2; }
    @else if $i < 30 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-rank-text-#{$i} {
    @if $i < 2 { @extend %background-percentile-text-bin-5; }
    @else if $i < 4 { @extend %background-percentile-text-bin-4e; }
    @else if $i < 6 { @extend %background-percentile-text-bin-4d; }
    @else if $i < 8 { @extend %background-percentile-text-bin-4c; }
    @else if $i < 10 { @extend %background-percentile-text-bin-4b; }
    @else if $i < 14 { @extend %background-percentile-text-bin-4a; }
    @else if $i <= 16 { @extend %background-percentile-text-bin-3; }
    @else if $i < 20 { @extend  %background-percentile-text-bin-2e;}
    @else if $i < 22 { @extend  %background-percentile-text-bin-2d;}
    @else if $i < 24 { @extend  %background-percentile-text-bin-2c; }
    @else if $i < 26 { @extend  %background-percentile-text-bin-2b; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 31 {
  .background-glg-thirtyone-rank-text-#{$i} {
    @if $i < 3 { @extend %background-percentile-text-bin-5; }
    @else if $i < 5 { @extend %background-percentile-text-bin-4e; }
    @else if $i < 7 { @extend %background-percentile-text-bin-4d; }
    @else if $i < 9 { @extend %background-percentile-text-bin-4c; }
    @else if $i < 11 { @extend %background-percentile-text-bin-4b; }
    @else if $i < 15 { @extend %background-percentile-text-bin-4a; }
    @else if $i <= 17 { @extend %background-percentile-text-bin-3; }
    @else if $i < 21 { @extend  %background-percentile-text-bin-2e;}
    @else if $i < 23 { @extend  %background-percentile-text-bin-2d;}
    @else if $i < 25 { @extend  %background-percentile-text-bin-2c; }
    @else if $i < 27 { @extend  %background-percentile-text-bin-2b; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 16 {
  .playoff-rank-text-#{$i} {
    @if $i <= 4 { @extend %percentile-text-bin-5; }
    @else if $i < 7 { @extend %percentile-text-bin-4; }
    @else if $i < 10 { @extend %percentile-text-bin-3; }
    @else if $i < 14 { @extend %percentile-text-bin-2; }
    @else if $i < 16 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-playoff-rank-text-#{$i} {
    @if $i <= 4 { @extend %background-percentile-text-bin-5; }
    @else if $i < 7 { @extend %background-percentile-text-bin-4; }
    @else if $i < 10 { @extend %background-percentile-text-bin-3; }
    @else if $i < 14 { @extend %background-percentile-text-bin-2; }
    @else if $i < 16 { @extend %background-percentile-text-bin-1a; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 360 {
  .ncaa-rank-text-#{$i} {
    @if $i <= 5 { @extend %percentile-text-bin-5; }
    @else if $i < 8 { @extend %percentile-text-bin-4; }
    @else if $i < 10 { @extend %percentile-text-bin-3; }
    @else if $i < 12 { @extend %percentile-text-bin-2; }
    @else if $i < 15 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-ncaa-rank-text-#{$i} {
    @if $i < 20 { @extend %background-percentile-text-bin-5; }
    @else if $i < 40 { @extend %background-percentile-text-bin-4e; }
    @else if $i < 60 { @extend %background-percentile-text-bin-4d; }
    @else if $i < 80 { @extend %background-percentile-text-bin-4c; }
    @else if $i < 100 { @extend %background-percentile-text-bin-4b; }
    @else if $i < 120 { @extend %background-percentile-text-bin-4a; }
    @else if $i < 140 { @extend %background-percentile-text-bin-4; }
    @else if $i < 160 { @extend %background-percentile-text-bin-3; }
    @else if $i < 180 { @extend  %background-percentile-text-bin-2f;}
    @else if $i < 200 { @extend  %background-percentile-text-bin-2e;}
    @else if $i < 220 { @extend  %background-percentile-text-bin-2d;}
    @else if $i < 240 { @extend  %background-percentile-text-bin-2c; }
    @else if $i < 260 { @extend  %background-percentile-text-bin-2b; }
    @else if $i < 280 { @extend %background-percentile-text-bin-1; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 15 {
  .glg-rank-text-#{$i} {
    @if $i <= 5 { @extend %percentile-text-bin-5; }
    @else if $i < 8 { @extend %percentile-text-bin-4; }
    @else if $i < 10 { @extend %percentile-text-bin-3; }
    @else if $i < 12 { @extend %percentile-text-bin-2; }
    @else if $i < 15 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-glg-rank-text-#{$i} {
    @if $i <= 5 { @extend %background-percentile-text-bin-5; }
    @else if $i < 8 { @extend %background-percentile-text-bin-4; }
    @else if $i < 10 { @extend %background-percentile-text-bin-3; }
    @else if $i < 12 { @extend %background-percentile-text-bin-2; }
    @else if $i < 15 { @extend %background-percentile-text-bin-1a; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 12 {
  .glg-twelve-rank-text-#{$i} {
    @if $i <= 2 { @extend %percentile-text-bin-5; }
    @else if $i < 4 { @extend %percentile-text-bin-4a; }
    @else if $i < 6 { @extend %percentile-text-bin-4; }
    @else if $i < 8 { @extend %percentile-text-bin-3; }
    @else if $i < 10 { @extend %percentile-text-bin-2; }
    @else if $i < 12 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-glg-twelve-rank-text-#{$i} {
    @if $i <= 2 { @extend %background-percentile-text-bin-5; }
    @else if $i < 4 { @extend %background-percentile-text-bin-4a; }
    @else if $i < 6 { @extend %background-percentile-text-bin-4; }
    @else if $i < 8 { @extend %background-percentile-text-bin-3; }
    @else if $i < 10 { @extend %background-percentile-text-bin-2; }
    @else if $i < 12 { @extend %background-percentile-text-bin-1a; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 15 {
  .glg-fifteen-rank-text-#{$i} {
    @if $i <= 5 { @extend %percentile-text-bin-5; }
    @else if $i < 8 { @extend %percentile-text-bin-4; }
    @else if $i < 10 { @extend %percentile-text-bin-3; }
    @else if $i < 12 { @extend %percentile-text-bin-2; }
    @else if $i < 15 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-glg-fifteen-rank-text-#{$i} {
    @if $i <= 5 { @extend %background-percentile-text-bin-5; }
    @else if $i < 8 { @extend %background-percentile-text-bin-4; }
    @else if $i < 10 { @extend %background-percentile-text-bin-3; }
    @else if $i < 12 { @extend %background-percentile-text-bin-2; }
    @else if $i < 15 { @extend %background-percentile-text-bin-1a; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 18 {
  .glg-eighteen-rank-text-#{$i} {
    @if $i <= 5 { @extend %percentile-text-bin-5; }
    @else if $i < 7 { @extend %percentile-text-bin-4a; }
    @else if $i < 9 { @extend %percentile-text-bin-4; }
    @else if $i < 12 { @extend %percentile-text-bin-3; }
    @else if $i < 15 { @extend %percentile-text-bin-2; }
    @else if $i < 18 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-glg-eighteen-rank-text-#{$i} {
    @if $i <= 5 { @extend %background-percentile-text-bin-5; }
    @else if $i < 7 { @extend %background-percentile-text-bin-4a; }
    @else if $i < 9 { @extend %background-percentile-text-bin-4; }
    @else if $i < 12 { @extend %background-percentile-text-bin-3; }
    @else if $i < 15 { @extend %background-percentile-text-bin-2; }
    @else if $i < 18 { @extend %background-percentile-text-bin-1a; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 21 {
  .glg-twentyone-rank-text-#{$i} {
    @if $i <= 5 { @extend %percentile-text-bin-5; }
    @else if $i < 8 { @extend %percentile-text-bin-4a; }
    @else if $i < 11 { @extend %percentile-text-bin-4; }
    @else if $i < 14 { @extend %percentile-text-bin-3; }
    @else if $i < 17 { @extend %percentile-text-bin-2; }
    @else if $i < 21 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-glg-twentyone-rank-text-#{$i} {
    @if $i <= 5 { @extend %background-percentile-text-bin-5; }
    @else if $i < 8 { @extend %background-percentile-text-bin-4a; }
    @else if $i < 11 { @extend %background-percentile-text-bin-4; }
    @else if $i < 14 { @extend %background-percentile-text-bin-3; }
    @else if $i < 17 { @extend %background-percentile-text-bin-2; }
    @else if $i < 21 { @extend %background-percentile-text-bin-1a; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@for $i from 1 through 28 {
  .glg-twentyeight-rank-text-#{$i} {
    @if $i <= 5 { @extend %percentile-text-bin-5; }
    @else if $i < 10 { @extend %percentile-text-bin-4a; }
    @else if $i < 15 { @extend %percentile-text-bin-4; }
    @else if $i < 20 { @extend %percentile-text-bin-3; }
    @else if $i < 24 { @extend %percentile-text-bin-2; }
    @else if $i < 28 { @extend %percentile-text-bin-1a; }
    @else { @extend %percentile-text-bin-1; }
  }
  .background-glg-twentyeight-rank-text-#{$i} {
    @if $i <= 5 { @extend %background-percentile-text-bin-5; }
    @else if $i < 10 { @extend %background-percentile-text-bin-4a; }
    @else if $i < 15 { @extend %background-percentile-text-bin-4; }
    @else if $i < 20 { @extend %background-percentile-text-bin-3; }
    @else if $i < 24 { @extend %background-percentile-text-bin-2; }
    @else if $i < 28 { @extend %background-percentile-text-bin-1a; }
    @else { @extend %background-percentile-text-bin-1; }
  }
}

@mixin blue_red_fill_percentile($v) {
  @if $v <= 10 {
    fill: #313695 !important;
  }
  @else if $v < 19 {
    fill: #4575b4 !important;
  }
  @else if $v < 28 {
    fill: #74add1 !important;
  }
  @else if $v < 37 {
    fill: #abd9e9 !important;
  }
  @else if $v < 46 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 55 {
    fill: #ffffbf !important;
  }
  @else if $v < 64 {
    fill: #fee090 !important;
  }
  @else if $v < 73 {
    fill: #fdae61 !important;
  }
  @else if $v < 82 {
    fill: #f46d43 !important;
  }
  @else if $v < 90 {
    fill: #d73027 !important;
  }
  @else {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_ppa($v) {
  @if $v <= 85 {
    fill: #313695;
  }
  @else if $v < 90 {
    fill: #4575b4;
  }
  @else if $v < 96 {
    fill: #74add1;
  }
  @else if $v < 101 {
    fill: #abd9e9;
  }
  @else if $v < 107 {
    fill: #e0f3f8;
  }
  @else if $v < 112 {
    fill: #ffffbf;
  }
  @else if $v < 118 {
    fill: #fee090;
  }
  @else if $v < 123 {
    fill: #fdae61;
  }
  @else if $v < 129 {
    fill: #f46d43;
  }
  @else if $v < 135 {
    fill: #d73027;
  }
  @else if $v >= 135 {
    fill: #a50026;
  }
}

@mixin blue_red_fill_fgp($v) {
  @if $v <= 225 {
    fill: #313695 !important;
  }
  @else if $v < 266 {
    fill: #4575b4 !important;
  }
  @else if $v < 307 {
    fill: #74add1 !important;
  }
  @else if $v < 348 {
    fill: #abd9e9 !important;
  }
  @else if $v < 390 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 431 {
    fill: #ffffbf !important;
  }
  @else if $v < 472 {
    fill: #fee090 !important;
  }
  @else if $v < 514 {
    fill: #fdae61 !important;
  }
  @else if $v < 555 {
    fill: #f46d43 !important;
  }
  @else if $v < 638 {
    fill: #d73027 !important;
  }
  @else if $v >= 638 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_efg($v) {
  @if $v <= 318 {
    fill: #313695 !important;
  }
  @else if $v < 354 {
    fill: #4575b4 !important;
  }
  @else if $v < 389 {
    fill: #74add1 !important;
  }
  @else if $v < 425 {
    fill: #abd9e9 !important;
  }
  @else if $v < 461 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 496 {
    fill: #ffffbf !important;
  }
  @else if $v < 532 {
    fill: #fee090 !important;
  }
  @else if $v < 568 {
    fill: #fdae61 !important;
  }
  @else if $v < 603 {
    fill: #f46d43 !important;
  }
  @else if $v < 674 {
    fill: #d73027 !important;
  }
  @else if $v >= 674 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_qefg($v) {
  @if $v <= 318 {
    fill: #313695 !important;
  }
  @else if $v < 354 {
    fill: #4575b4 !important;
  }
  @else if $v < 389 {
    fill: #74add1 !important;
  }
  @else if $v < 425 {
    fill: #abd9e9 !important;
  }
  @else if $v < 461 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 496 {
    fill: #ffffbf !important;
  }
  @else if $v < 532 {
    fill: #fee090 !important;
  }
  @else if $v < 568 {
    fill: #fdae61 !important;
  }
  @else if $v < 603 {
    fill: #f46d43 !important;
  }
  @else if $v < 674 {
    fill: #d73027 !important;
  }
  @else if $v >= 674 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_qefg_res($v) {
  @if $v <= -15 {
    fill: #313695 !important;
  }
  @else if $v < -12 {
    fill: #4575b4 !important;
  }
  @else if $v < -9 {
    fill: #74add1 !important;
  }
  @else if $v < -6 {
    fill: #abd9e9 !important;
  }
  @else if $v < -3 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 0 {
    fill: #ffffbf !important;
  }
  @else if $v < 3 {
    fill: #fee090 !important;
  }
  @else if $v < 6 {
    fill: #fdae61 !important;
  }
  @else if $v < 9 {
    fill: #f46d43 !important;
  }
  @else if $v < 15 {
    fill: #d73027 !important;
  }
  @else if $v >= 15 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_qfg($v) {
  @if $v <= 225 {
    fill: #313695 !important;
  }
  @else if $v < 266 {
    fill: #4575b4 !important;
  }
  @else if $v < 307 {
    fill: #74add1 !important;
  }
  @else if $v < 348 {
    fill: #abd9e9 !important;
  }
  @else if $v < 390 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 431 {
    fill: #ffffbf !important;
  }
  @else if $v < 472 {
    fill: #fee090 !important;
  }
  @else if $v < 514 {
    fill: #fdae61 !important;
  }
  @else if $v < 555 {
    fill: #f46d43 !important;
  }
  @else if $v < 638 {
    fill: #d73027 !important;
  }
  @else if $v >= 638 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_qfg_res($v) {
  @if $v <= -15 {
    fill: #313695 !important;
  }
  @else if $v < -12 {
    fill: #4575b4 !important;
  }
  @else if $v < -9 {
    fill: #74add1 !important;
  }
  @else if $v < -6 {
    fill: #abd9e9 !important;
  }
  @else if $v < -3 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 0 {
    fill: #ffffbf !important;
  }
  @else if $v < 3 {
    fill: #fee090 !important;
  }
  @else if $v < 6 {
    fill: #fdae61 !important;
  }
  @else if $v < 9 {
    fill: #f46d43 !important;
  }
  @else if $v < 15 {
    fill: #d73027 !important;
  }
  @else if $v >= 15 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_xefg($v) {
  @if $v <= 318 {
    fill: #313695 !important;
  }
  @else if $v < 354 {
    fill: #4575b4 !important;
  }
  @else if $v < 389 {
    fill: #74add1 !important;
  }
  @else if $v < 425 {
    fill: #abd9e9 !important;
  }
  @else if $v < 461 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 496 {
    fill: #ffffbf !important;
  }
  @else if $v < 532 {
    fill: #fee090 !important;
  }
  @else if $v < 568 {
    fill: #fdae61 !important;
  }
  @else if $v < 603 {
    fill: #f46d43 !important;
  }
  @else if $v < 674 {
    fill: #d73027 !important;
  }
  @else if $v >= 674 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_xefg_res($v) {
  @if $v <= -15 {
    fill: #313695 !important;
  }
  @else if $v < -12 {
    fill: #4575b4 !important;
  }
  @else if $v < -9 {
    fill: #74add1 !important;
  }
  @else if $v < -6 {
    fill: #abd9e9 !important;
  }
  @else if $v < -3 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 0 {
    fill: #ffffbf !important;
  }
  @else if $v < 3 {
    fill: #fee090 !important;
  }
  @else if $v < 6 {
    fill: #fdae61 !important;
  }
  @else if $v < 9 {
    fill: #f46d43 !important;
  }
  @else if $v < 15 {
    fill: #d73027 !important;
  }
  @else if $v >= 15 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_xfg($v) {
  @if $v <= 225 {
    fill: #313695 !important;
  }
  @else if $v < 266 {
    fill: #4575b4 !important;
  }
  @else if $v < 307 {
    fill: #74add1 !important;
  }
  @else if $v < 348 {
    fill: #abd9e9 !important;
  }
  @else if $v < 390 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 431 {
    fill: #ffffbf !important;
  }
  @else if $v < 472 {
    fill: #fee090 !important;
  }
  @else if $v < 514 {
    fill: #fdae61 !important;
  }
  @else if $v < 555 {
    fill: #f46d43 !important;
  }
  @else if $v < 638 {
    fill: #d73027 !important;
  }
  @else if $v >= 638 {
    fill: #a50026 !important;
  }
}

@mixin blue_red_fill_xfg_res($v) {
  @if $v <= -15 {
    fill: #313695 !important;
  }
  @else if $v < -12 {
    fill: #4575b4 !important;
  }
  @else if $v < -9 {
    fill: #74add1 !important;
  }
  @else if $v < -6 {
    fill: #abd9e9 !important;
  }
  @else if $v < -3 {
    fill: #e0f3f8 !important;
  }
  @else if $v < 0 {
    fill: #ffffbf !important;
  }
  @else if $v < 3 {
    fill: #fee090 !important;
  }
  @else if $v < 6 {
    fill: #fdae61 !important;
  }
  @else if $v < 9 {
    fill: #f46d43 !important;
  }
  @else if $v < 15 {
    fill: #d73027 !important;
  }
  @else if $v >= 15 {
    fill: #a50026 !important;
  }
}

@for $i from 1 through 100 {
  .blue-red-fill-percentile-#{$i} {
    @include blue_red_fill_percentile($i);
  }
}

@for $i from 85 through 135 {
  .blue-red-fill-ppa-#{$i} {
    @include blue_red_fill_ppa($i);
  }
}

@for $i from 225 through 638 {
  .blue-red-fill-fgp-#{$i} {
    @include blue_red_fill_fgp($i);
  }
}

@for $i from 318 through 674 {
  .blue-red-fill-efg-#{$i} {
    @include blue_red_fill_efg($i);
  }
}

@for $i from 318 through 674 {
  .blue-red-fill-qefg-#{$i} {
    @include blue_red_fill_qefg($i);
  }
}

@for $i from -15 through 15 {
  .blue-red-fill-qefg-res-#{$i} {
    @include blue_red_fill_qefg_res($i);
  }
}

@for $i from 225 through 638 {
  .blue-red-fill-qfg-#{$i} {
    @include blue_red_fill_qfg($i);
  }
}

@for $i from -15 through 15 {
  .blue-red-fill-qfg-res-#{$i} {
    @include blue_red_fill_qfg_res($i);
  }
}

@for $i from 318 through 674 {
  .blue-red-fill-xefg-#{$i} {
    @include blue_red_fill_xefg($i);
  }
}

@for $i from -15 through 15 {
  .blue-red-fill-xefg-res-#{$i} {
    @include blue_red_fill_xefg_res($i);
  }
}

@for $i from 225 through 638 {
  .blue-red-fill-xfg-#{$i} {
    @include blue_red_fill_xfg($i);
  }
}

@for $i from -15 through 15 {
  .blue-red-fill-xfg-res-#{$i} {
    @include blue_red_fill_xfg_res($i);
  }
}

@mixin yellow_purple_fill_percent($v) {
  @if $v < 50 {
    fill: mix($lightgreysmoke, $yellow-purple-percent-min, $v) !important;
  }
  @else {
    fill: mix($yellow-purple-percent-max, $lightgreysmoke, $v) !important;
  }
}

@for $i from 0 through 100 {
  .yellow-purple-fill-percent-#{$i} {
    @include yellow_purple_fill_percent($i);
  }
}

.mat-card > .loading {
  margin: 16px;
}

.mdc-tab--active {
  color: $color-thunder-navy !important;
  opacity: 1 !important;
}

// So far this is really only setup to handle selects (drop-downs), also only verified with legacy appearance
// once we upgrade Angular Material we can probably switch to outline appearance and tweak it's border radius and
// get rid of this
.mat-form-field.rounded {
  &.mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      border: 1px solid rgba(0, 0, 0, 0.54);
      border-radius: 15px;
      padding-left: 8px;

    }
    .mat-form-field-label {
      padding-left: 8px;
    }
    &.mat-form-field-can-float.mat-form-field-should-float {
      .mat-form-field-label {
        transform: translateY(-1.48em) scale(0.75) perspective(100px) translateZ(0.001px)
      }
    }
    .mat-form-field-underline {
      display: none;
    }
    .mat-select-arrow-wrapper {
      padding-right: 4px;
    }
  }
}

.mat-chip-list--selected-filters {
  .mat-chip-list-wrapper {
    margin-top: 0;
  }

  .mat-chip {
    font-size: 12px;
    font-weight: 400;

    @media #{$lt-md} {
      font-size: 10px;
    }

    &.mat-standard-chip {
      min-height: 24px;
      border-radius: 12px;
      background-color: transparentize($color-thunder-navy, 0.8);
      color: #0050af;

      @media #{$lt-md} {
        border-radius: 10px;
        min-height: 20px;
      }
    }
  }
}

.mat-mdc-tooltip {
  &.multi-line {
    white-space: pre-line;
  }
  &.center-text {
    text-align: center;
  }
}

.mat-mdc-tab.mdc-tab {
  padding: 0 8px;
  min-width: unset;
}

.mdc-tooltip--shown .mdc-tooltip__surface-animation {
  background: rgba(97,97,97,.9);
  color: white;
  border-radius: 4px;
  padding: 6px 8px;
}

.material-icons.inline-icon {
  font-size: 20px;
  vertical-align: text-bottom;
}

.eval-pill {
  display: flex !important;
  justify-content: space-between;
  flex-direction: row !important;
  align-items: center;
  margin-right: 16px !important;
  margin-top: 18px !important;
  margin-bottom: 0 !important;
  padding: 8px !important;

  .half-pill {
    font-weight: 500;
    width: 22px;
    height: 18px;
    text-align: center;
    margin-top: 0 !important;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.empty-skill {
      color: black;
      background-color: whitesmoke;
    }
  }
}

@for $i from 0 through 100 {
  .locs-#{$i} {
    &.highlighted {
      @if $i < 10 { // ML
        background-color: #4575b4;
        color: white;
      }
      @else if $i < 15 { // ML
        background-color: mix(#4575b4, #74add1, (15-$i)*0.2 * 100%);
        color: white;
      }
      @else if $i < 25 { // RO
        background-color: mix(#74add1, #abd9e9, (25-$i)*0.1 * 100%);
      }
      @else if $i < 35 { // RE
        background-color: mix(#abd9e9, #e0f3f8, (35-$i)*0.1 * 100%);
      }
      @else if $i < 45 { // KR
        background-color: mix(#e0f3f8, #edf7fa, (45-$i)*0.1 * 100%);
      }
      @else if $i < 55 { // S
        background-color: mix(#fee090, #fdae61, (55-$i)*0.1 * 100%);
      }
      @else if $i < 65 { // TS
        background-color: mix(#fdae61, #f46d43, (65-$i)*0.1 * 100%);
      }
      @else if $i < 75 { // C
        background-color: mix(#f46d43, #d9443b, (75-$i)*0.1 * 100%);
        color: white;
      }
      @else if $i < 80 { // F
        background-color: mix(#cb2e25, #CB2E25FF, (80-$i)*0.1 * 100%);
        color: white;
      }
      @else { // F
        background-color: #CB2E25FF;
        color: white;
      }
    }

    &.filled {
      @if $i < 10 { // ML
        fill: #4575b4;
      }
      @else if $i < 15 { // ML
        fill: mix(#4575b4, #74add1, (15-$i)*0.2 * 100%);
      }
      @else if $i < 25 { // RO
        fill: mix(#74add1, #abd9e9, (25-$i)*0.1 * 100%);
      }
      @else if $i < 35 { // RE
        fill: mix(#abd9e9, #e0f3f8, (35-$i)*0.1 * 100%);
      }
      @else if $i < 45 { // KR
        fill: mix(#e0f3f8, #edf7fa, (45-$i)*0.1 * 100%);
      }
      @else if $i < 55 { // S
        fill: mix(#fee090, #fdae61, (55-$i)*0.1 * 100%);
      }
      @else if $i < 65 { // TS
        fill: mix(#fdae61, #f46d43, (65-$i)*0.1 * 100%);
      }
      @else if $i < 75 { // C
        fill: mix(#f46d43, #d9443b, (75-$i)*0.1 * 100%);
        color: white;
      }
      @else if $i < 80 { // F
        fill: mix(#cb2e25, #CB2E25FF, (80-$i)*0.1 * 100%);
      }
      @else { // F
        fill: #CB2E25FF;
      }
    }

    &.text {
      @if $i < 15 {
        fill: white;
      }
      @else if $i >= 65 {
        fill: white;
      }
    }

    &.colored {
      @if $i < 10 { // ML
        color: #4575b4;
      }
      @else if $i < 15 { // ML
        color: mix(#4575b4, #74add1, (15-$i)*0.2 * 100%);
      }
      @else if $i < 25 { // RO
        color: mix(#74add1, #abd9e9, (25-$i)*0.1 * 100%);
      }
      @else if $i < 35 { // RE
        color: mix(#abd9e9, #e0f3f8, (35-$i)*0.1 * 100%);
      }
      @else if $i < 45 { // KR
        color: mix(#e0f3f8, #edf7fa, (45-$i)*0.1 * 100%);
      }
      @else if $i < 55 { // S
        color: mix(#fee090, #fdae61, (55-$i)*0.1 * 100%);
      }
      @else if $i < 65 { // TS
        color: mix(#fdae61, #f46d43, (65-$i)*0.1 * 100%);
      }
      @else if $i < 75 { // C
        color: mix(#f46d43, #d73027, (75-$i)*0.1 * 100%);
      }
      @else { // F
        color: #d73027;
      }
    }
  }
}

@for $i from 0 through 100 {
  .tsa-#{$i} {
    &.highlighted {
      @if $i < 10 {
        background-color: mix(#5e4fa2, #3288bd, (10-$i)*0.1 * 100%);
        color: white;
      }
      @else if $i < 20 {
        background-color: mix(#3288bd, #66c2a5, (20-$i)*0.1 * 100%);
        color: white;
      }
      @else if $i < 30 {
        background-color: mix(#66c2a5, #abdda4, (30-$i)*0.1 * 100%);
      }
      @else if $i < 40 {
        background-color: mix(#abdda4, #e6f598, (40-$i)*0.1 * 100%);
      }
      @else if $i < 50 {
        background-color: mix(#e6f598, #ffffbf, (50-$i)*0.1 * 100%);
      }
      @else if $i < 60 {
        background-color: mix(#ffffbf, #fee08b, (60-$i)*0.1 * 100%);
      }
      @else if $i < 70 {
        background-color: mix(#fee08b, #fdae61, (70-$i)*0.1 * 100%);
      }
      @else if $i < 80 {
        background-color: mix(#fdae61, #f46d43, (80-$i)*0.1 * 100%);
      }
      @else if $i < 90 {
        background-color: mix(#f46d43, #d53e4f, (90-$i)*0.1 * 100%);
      }
      @else {
        background-color: mix(#d53e4f, #9e0142, (100-$i)*0.1 * 100%);
        color: white;
      }
    }

    &.colored {
      @if $i < 10 {
        color: mix(#5e4fa2, #3288bd, (10-$i)*0.1 * 100%);
      }
      @else if $i < 20 {
        color: mix(#3288bd, #66c2a5, (20-$i)*0.1 * 100%);
      }
      @else if $i < 30 {
        color: mix(#66c2a5, #abdda4, (30-$i)*0.1 * 100%);
      }
      @else if $i < 40 {
        color: mix(#abdda4, #e6f598, (40-$i)*0.1 * 100%);
      }
      @else if $i < 50 {
        color: mix(#e6f598, #ffffbf, (50-$i)*0.1 * 100%);
      }
      @else if $i < 60 {
        color: mix(#ffffbf, #fee08b, (60-$i)*0.1 * 100%);
      }
      @else if $i < 70 {
        color: mix(#fee08b, #fdae61, (70-$i)*0.1 * 100%);
      }
      @else if $i < 80 {
        color: mix(#fdae61, #f46d43, (80-$i)*0.1 * 100%);
      }
      @else if $i < 90 {
        color: mix(#f46d43, #d53e4f, (90-$i)*0.1 * 100%);
      }
      @else {
        color: mix(#d53e4f, #9e0142, (100-$i)*0.1 * 100%);
      }
    }

    &.filled {
      @if $i < 10 {
        fill: mix(#5e4fa2, #3288bd, (10-$i)*0.1 * 100%);
      }
      @else if $i < 20 {
        fill: mix(#3288bd, #66c2a5, (20-$i)*0.1 * 100%);
      }
      @else if $i < 30 {
        fill: mix(#66c2a5, #abdda4, (30-$i)*0.1 * 100%);
      }
      @else if $i < 40 {
        fill: mix(#abdda4, #e6f598, (40-$i)*0.1 * 100%);
      }
      @else if $i < 50 {
        fill: mix(#e6f598, #ffffbf, (50-$i)*0.1 * 100%);
      }
      @else if $i < 60 {
        fill: mix(#ffffbf, #fee08b, (60-$i)*0.1 * 100%);
      }
      @else if $i < 70 {
        fill: mix(#fee08b, #fdae61, (70-$i)*0.1 * 100%);
      }
      @else if $i < 80 {
        fill: mix(#fdae61, #f46d43, (80-$i)*0.1 * 100%);
      }
      @else if $i < 90 {
        fill: mix(#f46d43, #d53e4f, (90-$i)*0.1 * 100%);
      }
      @else {
        fill: mix(#d53e4f, #9e0142, (100-$i)*0.1 * 100%);
      }
    }

    &.text {
      @if $i < 20 {
        fill: white;
      }
      @else if $i >= 90 {
        fill: white;
      }
    }
  }
}

@for $i from 1 through 1000 {
  .locsProbabilities-#{$i} {
    &.highlighted {
      @if $i < 50 {
        background-color: mix(#F9FDEB, #ffffff, (math.div($i, 100) + 0.5) * 100%);
      }
      @else if $i < 500 {
        background-color: mix(#9BD6AC, #ffffff, (math.div(($i - 50), 900) + 0.5) * 100%);
      }
      @else {
        background-color: mix(#35B0AB, #ffffff, (math.div(($i - 500), 1000) + 0.5) * 100%);
      }
    }

    &.colored {
      @if $i < 50 {
        color: mix(#F9FDEB, #ffffff, (math.div($i, 100) + 0.5) * 100%);
      }
      @else if $i < 500 {
        color: mix(#9BD6AC, #ffffff, (math.div(($i - 50), 900) + 0.5) * 100%);
      }
      @else {
        color: mix(#35B0AB, #ffffff, (math.div(($i - 500), 1000) + 0.5) * 100%);
      }
    }
  }
}

.video-tooltip-focused-container {
  position: relative;
  overflow: visible;
}

.cell-video-tooltip {
  z-index: 100 !important;
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: -4px !important;
  color: white !important;
  border: 2px solid white !important;
  box-sizing: border-box !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  cursor: pointer !important;
  opacity: 0 !important;

  &:hover {
    opacity: 1 !important;
  }

  .tooltip-inner {
    display: block !important;
    height: calc(100% - 4px) !important;
    border: 2px solid #f0f0f0 !important;
  }

  .icon-container {
    position: absolute !important;
    top: 50% !important;
    left: 100% !important;
    width: 24px !important;
    height: 24px !important;
    transform: translateY(-50%) !important;
    background-color: #f0f0f0 !important;
    border-top: 2px solid white !important;
    border-right: 2px solid white !important;
    border-bottom: 2px solid white !important;
    box-sizing: border-box !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;

    .mat-icon {
      color: gray !important;
      opacity: 1 !important;
      font-size: 14px !important;
      width: 14px !important;
      height: 14px !important;
    }
  }

  .tooltip-menu {
    display: flex !important;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.4) 2px 2px 2px 2px;
    min-width: 140px;

    &.display-above {
      position: absolute;
      bottom: 24px;
    }

    .tooltip-button {
      text-transform: none;
      background-color: white;
      min-width: 140px;
      cursor: pointer;
      height: 28px;
      text-align: left;
      padding-left: 8px;
      display: flex !important;
      align-items: center;

      &:first-of-type {
        border: none;
        border-bottom: 1px solid #dadce0;
        border-radius: 4px 4px 0 0;
      }

      &:last-of-type {
        border: none;
        border-radius: 0 0 4px 4px;
      }

      &:hover {
        background-color: #f1f1f1;
      }

      .tooltip-button-icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
        margin-left: 4px;
      }
    }
  }
}

.post-actions-container {
  @media #{$gt-sm} {
    border: 1px solid #dadce0;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 1046px;
    top: 14px;
    z-index: 3;
    background-color: white;

    &.scroll {
      top: 186px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }

  @media #{$gt-lg} {
    left: 1800px;
  }

  mat-icon {
    font-size: 20px;
  }

  .mat-icon-button {
    &:hover {
      background-color: #eeeeee;
    }
  }

  .bookmarks-image {
    width: 20px;
    height: 20px;
  }
}

.mat-mdc-tab-label, .mat-mdc-tab-link {
  @media #{$gt-sm} {
    font-size: 13px;
  }
}

.button-video-tooltip {
  z-index: 100 !important;
  position: absolute !important;
  display: flex !important;
  justify-content: center;
  align-items: center;;
  top: 4px !important;
  right: 4px !important;
  width: 30px;
  height: 20px;
  color: white !important;
  background-color: #f0f0f0 !important;
  border: 2px solid white !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  cursor: pointer !important;

  &:hover {
    background-color: #d0d0d0 !important;
  }

  .mat-icon {
    color: gray !important;
    opacity: 1 !important;
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
  }
}

.video-dialog {
  max-width: 100% !important;
  max-height: 100% !important;

  .mat-mdc-dialog-container, .mat-mdc-dialog-surface, .mat-mdc-dialog-content {
    padding: 0 !important;
    border-radius: 12px;
  }

  .mdc-dialog__content {
    overflow-x: visible;
  }
}
@for $i from 1 through 3000 {
  .reservePrices-#{$i} {
    &.highlighted {
      @if $i >= 1000 {
        color: white;
      }
      background-color: mix(#f7fcf5, #238b45, max(0, (1500 - ($i + 200)) * 0.000666666666667 * 100%));
    }

    &.colored {
      color: mix(#f7fcf5, #238b45, max(0, (1500 - ($i + 200)) * 0.000666666666667 * 100%));
    }
  }
}

@for $i from -3000 through -1 {
  .reservePrices-#{$i} {
    &.highlighted {
      background-color: mix(#f7fcf5, #c82a2a, max(0, (1500 + $i) * 0.000666666666667 * 100%));
    }

    &.colored {
      color: mix(#f7fcf5, #c82a2a, max(0, (1500 + $i) * 0.000666666666667 * 100%));
    }
  }
}

.new-playlist {
  .mat-pseudo-checkbox {
    display: none;
  }

  .add-playlist-container {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;

    .add-playlist-button {
      height: 24px;
      width: 24px;
      margin-right: 4px;
      font-size: 24px;
      margin-left: -3px;
    }
  }
}

%background-percentile-alt-text-bin-0 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.00) !important;}
%background-percentile-alt-text-bin-1 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.05) !important;}
%background-percentile-alt-text-bin-2 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.10) !important;}
%background-percentile-alt-text-bin-3 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.15) !important;}
%background-percentile-alt-text-bin-4 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.20) !important;}
%background-percentile-alt-text-bin-5 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.25) !important;}
%background-percentile-alt-text-bin-6 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.30) !important;}
%background-percentile-alt-text-bin-7 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.35) !important;}
%background-percentile-alt-text-bin-8 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.40) !important;}
%background-percentile-alt-text-bin-9 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.45) !important;}
%background-percentile-alt-text-bin-10 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.50) !important;}
%background-percentile-alt-text-bin-11 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.55) !important;}
%background-percentile-alt-text-bin-12 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.60) !important;}
%background-percentile-alt-text-bin-13 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.65) !important;}
%background-percentile-alt-text-bin-14 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.70) !important;}
%background-percentile-alt-text-bin-15 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.75) !important;}
%background-percentile-alt-text-bin-16 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.80) !important;}
%background-percentile-alt-text-bin-17 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.85) !important;}
%background-percentile-alt-text-bin-18 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.90) !important;}
%background-percentile-alt-text-bin-19 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 0.95) !important;}
%background-percentile-alt-text-bin-20 { text-align: center; padding: 2px; width: 20px; height: 20px; border-radius: 5px; display: flex; justify-content: center; align-items: center; background-color:rgba(255, 140, 0, 1.00) !important;}

@for $i from 0 through 100 {
  .background-percentile-alt-text-#{$i} {
    @if $i < 10 { @extend %background-percentile-alt-text-bin-2; }
    @else if $i < 20 { @extend %background-percentile-alt-text-bin-4; }
    @else if $i < 30 { @extend %background-percentile-alt-text-bin-6; }
    @else if $i < 40 { @extend %background-percentile-alt-text-bin-8; }
    @else if $i < 50 { @extend %background-percentile-alt-text-bin-10; }
    @else if $i < 60 { @extend %background-percentile-alt-text-bin-12; }
    @else if $i < 70 { @extend %background-percentile-alt-text-bin-14; }
    @else if $i < 80 { @extend %background-percentile-alt-text-bin-16; }
    @else { @extend %background-percentile-alt-text-bin-18; }
  }
}
